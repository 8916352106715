<template>
  <div class="basic-container">
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form :inline="true" :model="treeForm">
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">组织机构名称</td>
              <td class="valueTd">
                <el-input v-model="treeForm.name" placeholder="使用分组名称过滤列表，字母不区分大小写"
                          clearable></el-input>
              </td>
            </tr>
          </table>
        </el-form>

      </el-col>
    </el-row>
    <el-row  :gutter="20">
      <el-col :span="8">
        <div class="wxts_msg_search">
          <span>组织机构</span>
          <div class="f_t">
            <el-button-group>
              <el-button @click="getDataList()" :loading="dataTreeLoading">刷新</el-button>
            </el-button-group>
          </div>
        </div>
        <div class="data_tree">
          <el-row :gutter="20">
            <el-col>
              <el-tree
                  :data="dataTree"
                  node-key="id"
                  ref="dataTree"
                  default-expand-all
                  :filter-node-method="filterNode"
                  @current-change="handleCurrentChange"
                  :expand-on-click-node="false"
                  :props="{label:'name', children:'children'}"
                  v-loading="dataTreeLoading">
                              <span class="custom-tree-node" slot-scope="{ node }">
                                <span>{{ node.label }}</span>
                                <span>
<!--                                  <el-button type="text" size="mini" @click="modifyNode(node, data)">修改</el-button>
                                  <el-button type="text" size="mini" @click="removeNode(data)">删除分组</el-button>
                                  <el-button type="text" size="mini" @click="addOrUpdateHandle(data)">追加子分组</el-button>-->
                                </span>
                              </span>
              </el-tree>
            </el-col>
          </el-row>
        </div>

      </el-col>
      <el-col :span="16">
        <el-form :inline="true" :model="dataForm">
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">配置名称</td>
              <td class="valueTd">
                <el-input v-model="dataForm.name" placeholder="配置名称" clearable></el-input>
              </td>
              <td class="titleTd width80px">发布状态</td>
              <td class="valueTd">
                <sysDictSelect v-model="dataForm.ifPublish" dictType="publish_status"
                               placeholder="请选择发布状态"/>
              </td>
              <!--                            <td class="titleTd width80px">配置类型</td>
                                          <td class="valueTd">
                                              <sysDictSelect v-model="dataForm.reportType" dictType="report_type"
                                                             placeholder="请选择配置类型"/>
                                          </td>-->
            </tr>
          </table>
        </el-form>

        <div class="wxts_msg_search">
          <span>配置列表{{categoryName ? ('('+categoryName+')') : ''}}</span>
          <div class="f_t">
            <el-button-group>
              <el-button @click="currentChangeHandle(1)" :disabled="dataListLoading">查询</el-button>
              <el-button :disabled="dataListLoading" type="primary" @click="addOrUpdate()">新增</el-button>
              <el-button :disabled="dataListLoading" type="primary" @click="copyReport()">复制</el-button>
            </el-button-group>
          </div>
        </div>
        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
                  style="width: 100%;">
          <el-table-column prop="categoryName" header-align="center" align="center" label="组织机构名称" >
          </el-table-column>
          <el-table-column prop="name" header-align="center" align="center" label="配置名称" >
          </el-table-column>
          <el-table-column prop="publishStatus" header-align="center" align="center" label="发布状态" width="80" >
            <template slot-scope="scope">
              <sysDict type="publish_status" :value="scope.row.publishStatus"/>
            </template>
          </el-table-column>
          <!--                    <el-table-column prop="reportType" header-align="center" align="center" label="配置类型" width="80">
                                  <template slot-scope="scope">
                                      <sysDict type="report_type" :value="scope.row.reportType"/>
                                  </template>
                              </el-table-column>-->
          <!--<el-table-column prop="showUrl" header-align="center" align="center" label="展示url"
                           :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.ifPublish === 2"
                        @click="previewHandle(scope.row.id,scope.row.showUrl)"
                        style="cursor: pointer;color: blue">
                       {{scope.row.showUrl}}
                  </span>
                  <span v-else>
                      {{'&#45;&#45;'}}
                  </span>
              </template>
          </el-table-column>
          <el-table-column prop="showEndTime" header-align="center" align="center" label="截至日期" width="100">
              <template slot-scope="scope">
                  <span v-if="scope.row.ifPublish === 1">
                      {{'&#45;&#45;'}}
                  </span>
                  <span v-else>
                      {{scope.row.showEndTime}}
                  </span>
              </template>
          </el-table-column>-->
          <el-table-column  header-align="center" align="center" width="220" label="操作">
            <template slot-scope="scope">
              <el-button type="text" class="update-button" size="small"
                         @click="addOrUpdate(scope.row.id)">修改
              </el-button>
              <el-button type="text" class="del-button" size="small" @click="deleteHandle(scope.row.id)">
                删除
              </el-button>
              <el-button type="text" class="update-button" size="small"
                         @click="editTemplateHandle(scope.row.id)">模板编辑
              </el-button>
              <el-button type="text" class="del-button" size="small" @click="publishHandle(scope.row.id)"
                         v-if="scope.row.publishStatus === 1">发布
              </el-button>
              <el-button type="text" class="del-button" size="small" @click="againPublishHandle(scope.row.id)"
                         v-if="scope.row.publishStatus === 3 ">重新发布
              </el-button>
              <el-button type="text" class="del-button" size="small" @click="revokeHandle(scope.row.id)"
                         v-if="scope.row.publishStatus ===  2 || scope.row.publishStatus === 3">撤回
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 弹窗, 新增 / 修改 分组展示-->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 弹窗, 新增 / 修改 配置列表-->
    <report-add-or-update v-if="ReportaddOrUpdateVisible" ref="ReportaddOrUpdate" @refreshDataList="getReportList"/>
    <!-- 弹窗, 新增 / 修改 发布-->
    <publish v-if="publishVisible" ref="publish" @refreshDataList="getReportList"></publish>
    <report-copy v-if="copyVisible" ref="ReportCopy" @refreshDataList="getReportList"></report-copy>

  </div>
</template>

<script>
import AddOrUpdate from "./add-or-update";
import ReportAddOrUpdate from "./report-add-or-update";
import ReportCopy from "./report-copy";
import publish from "./publish";
import {getAllList, deleteData} from '@/api/hbi/reportcategory.js';
import { getAlllist as getAllUnitList } from '@/api/common/projectorgunit.js'
import {treeDataTranslate} from '@/utils/index.js';
import {getReportList, deleteData as deleteInfo, revokeInfo, publishInfo,againPublishInfo} from '@/api/hbi/report.js';

export default {
  components: {
    AddOrUpdate, ReportAddOrUpdate, publish,ReportCopy
  },
  data() {
    return {
      dataTree: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataTreeLoading: false,
      categoryId: '',
      categoryName: '',
      year: '',
      month: '',
      day: '',
      ifUpdate: '',
      treeForm: {
        name: '',
      },
      dataForm: {
        name: null,
        reportType: null,
        publishStatus: null
      },
      addOrUpdateVisible: false,
      ReportaddOrUpdateVisible: false,
      publishVisible: false,
      copyVisible:false
    };
  },
  activated() {
    this.getDataList();
    this.getReportList()
  },
  methods: {
    /**
     *  选中某个分组时
     */
    handleCurrentChange(val) {
      this.categoryId = val.id
      this.categoryName = val.name

      this.currentChangeHandle(1)
      //根据分组ID查询配置列表的数据
    },

    // 获取分组展示数据列表
    getDataList() {
      this.dataTreeLoading = true
      const params_ = {}
      getAllUnitList(params_).then(({data}) => {
        if (data && data.code === 0) {
          const list = data.data
          this.dataTree = list && list.length > 0 ? treeDataTranslate(data.data, "id", "orgAccountId"): [];
          this.categoryName = '';
          this.treeForm.name = '';
          this.categoryId = ''
        } else {
          this.dataTree = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataTreeLoading = false
        //没选择分组时的配置列表
        this.getReportList()
      })
    },

    //根据分组ID获取数据
    getReportList() {
      this.dataListLoading = true;
      getReportList({
        categoryId: this.categoryId,
        name: this.dataForm.name,
        reportType: this.dataForm.reportType,
        publishStatus: this.dataForm.publishStatus,
        page: this.pageIndex,
        limit: this.pageSize
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
        }
      })
          .finally(() => {
            this.dataListLoading = false;
          });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getReportList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getReportList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改 配置列表
    addOrUpdate(id) {
      if ((this.categoryId == '' || this.categoryId == null) && (id == '' || id == null)) {
        this.$alert('请先选择需要新增配置的组织机构后再进行新增配置的操作，如无所需的组织机构信息请先新增加组织机构信息。', '提示', {
          confirmButtonText: '确定'
        });
        return;
      }
      this.ReportaddOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.ReportaddOrUpdate.init(id, this.categoryId, this.categoryName)
      })
    },
    // 新增 / 修改 配置列表
    copyReport(id) {
      if ((this.categoryId == '' || this.categoryId == null) && (id == '' || id == null)) {
        this.$alert('请先选择需要新增配置的组织机构后再进行新增配置的操作，如无所需的组织机构信息请先新增加组织机构信息。', '提示', {
          confirmButtonText: '确定'
        });
        return;
      }
      this.copyVisible=true
      this.$nextTick(() => {
        this.$refs.ReportCopy.init(id, this.categoryId, this.categoryName)
      })
    },
    // 根据配置列表ID删除
    deleteHandle(id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id;
      });
      this.$confirm(`确定进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteInfo(ids).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            //刷新配置列表
            this.getReportList(this.categoryId)
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => {
      })
    },

    // 发布操作
    publishHandle(id) {
      /*  this.publishVisible = true
        this.$nextTick(() => {
            this.$refs.publish.init(id, this.categoryId)
        })*/
      this.$confirm(`确定进行发布操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        const data_ = {
          'id': id ,
        }
        publishInfo(data_).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500
            })
            //刷新配置列表
            this.getReportList(this.categoryId)
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => {
      })
    },
    // 重新发布操作
    againPublishHandle(id) {
      this.$confirm(`确定进行发布操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        const data_ = {
          'id': id ,
        }
        againPublishInfo(data_).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500
            })
            //刷新配置列表
            this.getReportList(this.categoryId)
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => {
      })
    },
    // 撤回操作
    revokeHandle(id) {
      this.$confirm(`确定进行撤回操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        revokeInfo(id).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            //刷新配置列表
            this.getReportList(this.categoryId)
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => {
      })
    },

    /** 修改节点 */
    modifyNode(node, data) {
      let pName = node.parent.data.name
      this.$set(data, 'parentName', pName);
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.modifyNode(data)
      })
    },
    /** 添加节点 */
    addOrUpdateHandle(data) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.addNode(data)
      })
    },
    /** 删除节点 */
    removeNode(data) {
      this.$confirm(`确定对分组【${data.name}】进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataTreeLoading = true
        deleteData([data.id]).then(({data}) => {
          if (data && data.code === 0) {
            this.$message.success('操作成功')
            this.getDataList()
            this.getReportList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataTreeLoading = false
        })
      }).catch(() => {
      })
    },

    /** 树信息过滤 */
    filterDataList() {
      this.$refs.dataTree.filter(this.treeForm.name);
    },
    /** 树信息过滤实现 */
    filterNode(value, data) {
      if (!value) return true;
      let v = value.toLowerCase()
      let d = data.name.toLowerCase()
      return d.indexOf(v) !== -1;
    },

    // 模板编辑
    editTemplateHandle(id) {
      let routeUrl = this.$router.resolve({
        name: 'hbi_report_edit',
        query: {id: id}
      })
      window.open(routeUrl.href, id)
    },

    // 模板预览
    previewHandle(id) {
      // let routeUrl = this.$router.resolve({
      //     name: 'hbi_report_preview',
      //     query: {id: id}
      // })
      // window.open(routeUrl.href, id)
      //重新打开一个页面
      window.open(`/hbi/report/preview?id=${id}`, '_blank')
    },
  },
  watch: {
    'treeForm.name': {
      handler: function (newVal) {
        if (newVal) {
          this.filterDataList()
        }else{
          this.getDataList()
        }
      },
      deep: true
    },
  }
};
</script>
<style scoped>
.data_tree span.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

</style>
